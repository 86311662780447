.radioWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    font-weight: 200;
}

.list {
    list-style-type: decimal;
    font-weight: bold;
}

.list li {
    padding: 10px;
}

.toothImages {
     display: grid;
    grid-template-columns: repeat(8, 65px);
    grid-gap: 0;
    margin-bottom: 3%;
}

.toothImage {
    display: inline;
    padding: 0;
    position: relative;
    margin: 0;
}

.toothImage img:nth-child(1) {
    width: 65px;
    height: 65px;
    padding: 0;
    margin: 0;
}

.toothImage img:nth-child(2) {
    width: 35px;
    height: 35px;
    position: absolute;
    z-index: 10;
    top: 20%;
    left: 30%;
}

.toothImage img:hover{
    cursor: pointer;
}

.UserData ul {
    margin-top: 0;
}

.LeftGrid{
	border-right: 1px solid #ddd;
}

.userImage {
    display: inline;
}

.userImage img{
    max-width: 80px;
    max-height: 80px;
    display: inline;
}

.UserData {
    display: block;
    border-bottom: 1px solid;
}

span.UserDataLabel {
    color: #808080;
    width: 110px;
    display: inline-block;
}

.UserData ul li{
    list-style-type: none;
    margin-top: 20px;
}


.LeftGrid ul {
    border-bottom:1px solid #ddd;
    margin-bottom: 10px;
}
.UploadedImages img {
    width: 80%;
    height: 80%;
}

.gridList{
    width: 500;
    height: 450;
  }

@media only screen and (max-width: 768px) {
  .toothImages {
            overflow-x: scroll;
    }

}

@media only screen and (max-width: 600px) {
  .toothImages {
        overflow-x: scroll;
    }
}

