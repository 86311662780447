.page {
    padding-top: 40px;
    padding-right: 60px;
    padding-bottom: 40px;
    padding-left: 60px;
}

.imgLogo {
    width: 150px;
}

.heading {
    text-decoration: underline;
    text-align: center;
}

.form {
    padding: 10px;
    border: 1px solid #000000;
    border-top: none;
    font-size: 14px;
}

.formFirst {
    border-top: 1px solid #000000;
}

.question {
    font-weight: bold;
    overflow-wrap: break-word;
}

.answer {
    overflow-wrap: break-word;
    white-space: pre-line;
}

.img {
    width: 90%;
    padding-bottom: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgPos {
    width: 90%;
    padding-bottom: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hidden {
    display: none;
}