
.tablinks{display:inline-block;position: relative;position: relative;
    z-index: 9;}

.headerWrapper header {
    box-shadow: none;
}

.container
{
  padding: 16px;
}

.tab button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: .95rem;
  transition: 0.3s;
  font-size: 14px;
}


.tab button:hover {
      background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
}


.tab button.active {
      background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
}
.tabcontent.active{display:block;}
/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 6px 6px 12px;
  border: 1px solid #ccc;    margin-top: -1px;height:calc(100vh - 90px);position:relative;

}
#ifrmbox{width:70%; margin:0 auto; height:100%;}
.tabnav{display:none; margin:0px; padding:0px;position: absolute;
    top: 46px;
    width: 100%;
    z-index: 999 !important;

    left: 0px;
    background-color: #fff;}
.tabnav li
{
  display:block;
  margin:0px;
  padding: .5rem;
  border: 1px solid #ccc;
  border-top: none;
}

.tabnav li button, .tabnav li button:hover, .tabnav li button.active{border:none;}

.plan-option{display:none; margin:0px; padding:0px;position: absolute;
    top: 46px;
    width: 100%;
    z-index: 999 ;
   text-align:right;
    right: 0px;
    background-color: #fff;}
.plan-option li{display:block;}
.plan-option li a{    padding: 1rem 1rem 0rem;
    display: block;
    font-size: 14px;
    color: #000;
    text-decoration: none;}
.openav{display:block;}

.tabcontentWrap{width:100%;}
.chatboxWrap{z-index: 999; border: solid 1px #4d4d4d;}
.treatmentplan{background-color: #445994;
    color: #fff;
    width: 100%;
    border: none;
    padding: 1rem;
    text-align: right;}

.msgroom{overflow-y: scroll; padding: 8px}
.msgroom::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.msgroom::-webkit-scrollbar
{
	width: 6px !important;
	background-color: #F5F5F5;
}

.msgroom::-webkit-scrollbar-thumb
{
	background-color: #5954a0;
}

.msgBody{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.msgBody p{
    margin-block-end: 0px !important;
    margin-block-start: 0px !important;
}

[data-rmiz-wrap="visible"], [data-rmiz-wrap="hidden"]{
    width: 100%;
}

.msgtxt{width:100%;clear:both;}
.msgtxt div{
    position:relative;
    border-radius: 8px;
    color: #fff;
    padding: 8px 10px;
margin-bottom: 1.5rem; font-size:14px; width:90% }
.sent{background-color: #7b92a0;float:left;}
.receive{background-color: #797084; float:right;}
.chatinfo{position:absolute; bottom:-15px; font-size:12px; color:#666; width:100%;}

.receive .chatinfo{right:0px;text-align:right;}
.sent .chatinfo{left:0px;}

.uploadbtn{position:relative;padding: 0px 0px 0px 5px;
    color: #fff;
    font-size: 26px;
    line-height: 26px;
    display: inline-block;
    vertical-align: sub;}
	.uploadbtn i{cursor:pointer;}

.activeDiv{background: #445994;padding: .5rem;
    bottom: 0px;}
.uploadbtn input[type=file] {

  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;width: 30px;
    height: 30px;
}
.txtfld{background-color:#fff; border:none;width:70%;padding: .5rem;}
.sndbtn{background-color:#7b92a0; border:none;padding: .5rem;width:20%;text-align: center;
    color: #fff;}
.caseIDWrap{background-color:#ffffff;padding: .5rem 0 .5rem 4rem;
    position: relative;}
.caseIDWrap img{position: absolute;left: 10px;}
.caseIDWrap p span{display:block;}
@media (max-width: 767px) {
	.tabcontentWrap, .chatboxWrap{width:100%; float:none;}
	.chatboxWrap{position: relative;}


}