.radioWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    font-weight: 200;
}

.textField {
  width: 100%
}

.optionLists{
    width: 100%;
    margin-left: 20%;
    margin-top: 6px;
    margin-bottom: 20px;
}

p.listHeading {
    font-weight: 100;
    color: rgba(0, 0, 0,0.8);
}

.list {
    list-style-type: decimal;
    font-weight: bold;
}

.list li {
    padding: 10px;
}

.toothImages {
     display: grid;
    /* grid-template-columns: repeat(16, 65px);
    grid-template-rows: repeat(2, 65px); */
    grid-gap: 0;
    margin-bottom: 3%;
}

.toothImage {
    display: inline;
    padding: 0;
    position: relative;
    margin: 0;
}

.toothImage img:nth-child(1) {
    width: 65px;
    height: 65px;
    padding: 0;
    margin: 0;
}

.toothImage img:nth-child(2) {
    width: 35px;
    height: 35px;
    position: absolute;
    z-index: 10;
    top: 20%;
    left: 30%;
}

.toothImage img:hover{
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .toothImages {
        overflow-x: scroll;
        /* grid-template-columns: repeat(16, 45px);
        grid-template-rows: repeat(2, 45px); */
    }

    .toothImage img:nth-child(1) {
        width: 45px;
        height: 45px;
    }

    .toothImage img:nth-child(2) {
        width: 25px;
        height: 25px;
    }
    .radioWrapper {
    display: block !important;
    padding-left: 0px !important;
}
}

@media only screen and (max-width: 600px) {
  .toothImages {
        overflow-x: scroll;
        /* grid-template-columns: repeat(16, 45px);
        grid-template-rows: repeat(2, 45px); */
    }

    .toothImage img:nth-child(1) {
        width: 45px;
        height: 45px;
    }

    .toothImage img:nth-child(2) {
        width: 25px;
        height: 25px;
    }
}

.tableItem{
    border: 1px solid #EDEDED;
    border-top: none;
    padding: 5px;
}

.heading {
    text-align: left;
    font-weight: 600;
    font-size: 20;
}