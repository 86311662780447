.uploadBtnWrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor:pointer;
}

.uploadBtnWrapper .btn {
    background-color: #9998da;
    padding: 8px;
    /* border-radius: 50%; */
    border:none;
    font-size: 20px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    color:#fff;

}

.uploadBtnWrapper input[type=file] {
    height: 60px;
    width: 60px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;

}

.customBadge > span{
  background: red;
  color:white;
}
