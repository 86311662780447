.tableItem {
    border: 1px solid #EDEDED;
    border-top: none;
    padding: 5px;
}

.popupTableItem {
    border: 1px solid #EDEDED;
    padding: 5px;
}

.heading {
    text-align: left;
    font-weight: 600;
    font-size: 20;
}

.img {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.imgPos {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.imgtext {
    display: block;
}

.hide {
    display: none;
}