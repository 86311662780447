.customButton button {
    background-color: #593687;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 10px;
    cursor: pointer;
}
.customButton
{
    margin-top: 30px;
}

.next{
    float: right;
}