.NewCaseModalButton {
    /* border: 1px solid #4c4a8f !important;
    color: white !important;
    padding: 19px !important;
    min-width: 120px !important; */
    background: white !important;
    background: linear-gradient(43deg, rgb(87, 87, 148) 0%, rgb(34, 41, 76) 100%) !important;
    width: 100%;
    border: solid 0.8px #4c4a8f !important;
    color: white !important;
    padding: 16px 8px !important;
    font-weight: 300 !important;
    display: block !important;
}

.customBadge > span{
    background: red;
    color:white;
}

@media only screen and (max-width: 600px) {

    .actionNavBar{
        display: flex;
        width: 100%;
        flex-direction: row;
        white-space: nowrap;
        overflow: scroll;
    }

}
