.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  /* font-family: Microsoft JhengHei; */
  font-family: 'Montserrat', sans-serif;
}

.MUIDataTableToolbar-icon-218:hover, .MUIDataTableToolbar-icon-264:hover{
  color: grey !important;
}

.MuiFormLabel-root{
  color: black !important;
  font-weight: 300 !important;
}

.MuiInput-underline:after{
  border-bottom: 2px solid black !important;
}

.MuiInputBase-input{
  font-weight: 300 ;
}

.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: black !important;
}

.MuiTableRow-root{
  cursor: pointer;
}

h3{
  margin: 0px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked{
  color: #9573c1 !important;
}

.navDiv {
  height: 100%;
  overflow-y: scroll;
}

/* width */
.navDiv::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.navDiv::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.navDiv::-webkit-scrollbar-thumb {
  background: #061a2e;
}

/* Handle on hover */
.navDiv::-webkit-scrollbar-thumb:hover {
  background: #555;
}
