#loading {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	display: block;
	opacity: 0.3;
	background-color: #f0ecec;
	z-index: 99;
	text-align: center;
}
.ldBarMain{
	width: 50%;
	height: 50%;
}
.ldBar path.mainline {
  stroke-width: 10;
  stroke: rgb(75, 77, 78);
  stroke-linecap: round;
}
.ldBar path.baseline {
  stroke-width: 9;
  stroke: #999999;
  stroke-linecap: round;
}
.ldBar-label {
  color: rgb(20, 20, 20);
  font-family: 'varela round';
  font-size: 1.5em;
  font-weight: 900;
}

html {
  margin: 0;
}
body {
  margin: 0;
}
/* * {
  overflow-y: hidden;
  overflow-x: hidden;
} */

canvas {
	outline: none;
	position: absolute;
	top: 0;
	left: 0;
}
p {
	 margin:0 ;
	 margin-left:5px;
}

#main{
	display: flex;
	flex-flow: column;
	float: left;
	width: 100%;
	height: 100vh;
	margin: 0px;
	padding: 0px;
}
#container3D{
	flex: 1 1 auto;
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
}

html {
  max-height: 100vh;
}
body {
  max-height: 100vh;
}

.label {
	color: #FFF;
	font-family: sans-serif;
	padding: 2px;
	background: rgba( 0, 0, 0, .6 );
}
.top {
	flex: 0 1 auto;
	position: relative;
	justify-content: center;
	align-items: center;
	width:100%;
	background-color: transparent !important;
	z-index: 1
}
.bottom {
	flex: 0 1 40px;
	position: relative;
	justify-content: center;
	align-items: center;
	width:100%;
	height:50px !important;
	background-color: transparent !important;
	z-index: 1
}
.bottom-1 {
	width: 100%;
	display: flex;
	justify-content: left;
	align-items: center;
	background:rgb(50, 54, 57);
	padding: 0px;
	padding-left: 5px;
	padding-right: 5px;
	color: rgb(215, 210, 210);
}

.bottom-1 li{
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 6px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 4px;
	cursor:pointer;
}
.bottom-1 select{
	border-radius: 10px;
	cursor:pointer;
	color: rgb(46,43,44);
	background: rgb(215, 210, 210);
}
.bottom-1 select option{
	border-radius: 10px;
	cursor: move;
	color: rgb(46,43,44);
}
.top li:hover, .left li:hover , .mtop li:hover{
	background-color: rgb(97, 97, 97);
}

.top-1 {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid rgb(127, 121, 107);
	background:rgb(50, 54, 57);
	padding: 0px;
	padding-left: 5px;
	padding-right: 5px;

}
.top-2 {
	width: 100%;
	display: flex;
	justify-content: left;
	align-items: center;
	background:rgb(50, 54, 57);
	padding: 0px;
	padding-left: 5px;
	padding-right: 5px;
	color: rgb(215, 210, 210);
}

.top-1 li{
	margin-top: 5px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 4px;
	cursor:pointer;
	color: rgb(215, 210, 210);
	font-size: 12px;
	user-select: none;

}
.top-2 li{
	display: inline-block;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding: 5px;
	border-radius: 4px;
	cursor:pointer;
	color: rgb(215, 210, 210);
	font-size: 12px;
}
.divider{
	width: 1px;
	height: 20px;
	background-color: rgb(127, 121, 107);
	margin: 4px;
}
#optionModel{
	background-color: transparent;
	color: rgb(215, 210, 210);
	border: none;
}

.top-1 img,.left-1 img,.bottom-1 img{
	height:24px;
	width:24px;
}

.top-2 img {
	height:20px;
	width:20px;
}

#rightPanel{
	width: 265px;
	position: absolute;
	top: 10%;
	left: 85%;
	background: transparent;
	margin: 0px;
	padding: 0px;
	z-index: 99;
}
.rightPanel-1{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	border-radius: 10px;
	background:rgb(194, 194, 194);
	padding: 20px;
	padding-left: 5px;
	padding-right: 5px;
	border: 2px dashed rgb(0,0,0);
}
.rightPanel-1 img{
	padding: 10px;
	height:100px;
	width:100px;
	background: rgb(112, 114, 116);
	border-radius: 50%;
	border: 2px dashed rgb(0,0,0);
}

.rightPanel-1 li{
	display: inline-block;
	padding: 5px;
	border-radius: 10px;
	cursor:pointer;
}
#rangeSlider{
	-webkit-appearance: none;
	width: 100%;
	height: 12px;
	background:transparent;
	outline: none;
	opacity: 1;
	-webkit-transition: .2s;
	transition: opacity .2s;
	border-radius: 7px;
	z-index: 999;
}

#rangeSlider::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 40px;
	/* border-radius: 5px; */
	background: rgb(215, 210, 210);
	cursor: pointer;
	opacity: 50%;
  }
#rangeSlider::-moz-range-thumb {
	height: 40px; /* Slider handle height */
	background:  rgb(215, 210, 210); /* Green background */
	cursor: pointer; /* Cursor on hover */
	opacity: 50%;

  }
#rangeTemp{
	position: absolute;
	left: 10px;
	right: 10px;
	display: flex;
  }
.tempDiv{
	-ms-flex: 1;  /* IE 10 */
	flex: 1;
	width: 50px;
	height: 10px;
	margin-left: 4px;
	margin-right: 4px;
	background-color: transparent;
	border: 2px solid rgb(215, 210, 210);;
	border-radius: 4px;
}
.labelIPR{
	font-size: 20px;
	font-weight: bold;
	z-index: -1;
	pointer-events: none;
	color: white;
	background-color: black;
	border-radius: 5%;
	/* width: 20px; */
	text-align: center;
	padding: 5px;
}
#occlusionBar{
	color: white;
	fill: #fff;
	margin-top: 20px;
	background-color: rgb(194, 194, 194);
	border: 1px solid rgb(0,0,0);
	border-radius: 10px;
	height: 80px;
	width: 100%;
	display: flex;
	flex-flow: row;
	display: flex;
	justify-content: center;
	align-items: center;
}

#occlusionBar img{
	height: 120%;
	width:  100%;
}
.scaleLabel{
	font-size: 15px;
	margin-left: 18px;
}

#txPlanSelect{
	padding: 8px 0px 8px 8px;
}

@media screen and (max-width: 800px) {
	.makeStyles-container-1{
		min-width: unset !important;
	}
 }

.sliderBtns {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.sliderBtnsTop {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  justify-content: center;
  padding: 6px 0px;
  height: 66px;
}

.sliderBtns::-webkit-scrollbar, .sliderBtnsTop::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.sliderBtns::-webkit-scrollbar-track, .sliderBtnsTop::-webkit-scrollbar-track {
  background: #2b2b2b;
}

/* Handle */
.sliderBtns::-webkit-scrollbar-thumb, .sliderBtnsTop::-webkit-scrollbar-thumb {
  background: linear-gradient(34deg, rgba(120,92,191,1) 39%, rgba(13,131,207,1) 100%); 
}

/* Handle on hover */
.sliderBtns::-webkit-scrollbar-thumb:hover, .sliderBtnsTop::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(34deg, rgb(183, 164, 232) 39%, rgb(140, 207, 252) 100%); 
}