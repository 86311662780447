.card {
    border: 1px black dotted;
    margin: 15px;
    background: #ddd;
    position:relative;
}

span.ImageLabelText {
    color: red;
    margin-left: 15px;
}

.mirrorImage:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 3px dotted #6d6d6d;
    transform: translate(-50%);
    z-index: 999;
}

.uploadFooter div {
    display: inline;
}

.mirrorText1{

    position: absolute;
    left: 0;
    bottom: 0;
    color: #6d6d6d;
    font-size: 24px;
}

.mirrorText2{
    position: absolute;
    right: 0;
    bottom: 0;
    color: #6d6d6d;
    font-size: 24px;
}
.customButton button {
    background-color: #593687;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 10px;
    cursor: pointer;
}
.customButton
{
    margin-top: 30px;
}

.cardImage{
    min-height: 350px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
}
.cardImage img {
    display: block;
    margin: auto;
    width: 75%;
    object-fit: contain;
    height: 350px;
}

.ImageCheckbox {
    margin-left: 15px;
    margin-top: -10px;
}
.uploadBtnWrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor:pointer;
  width: 100%;
}

.uploadBtnWrapper .btn {
    background-color: #9998da;
    padding: 8px;
    /* border-radius: 50%; */
    border:none;
    font-size: 20px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    color:#fff;

}

.uploadBtnWrapper .remove {
    padding: 8px;
    border-radius: 5%;
    border: none;
    font-size: 15px;
    width: auto;
    height: auto;
    cursor: pointer;
    background-color: #c13737;
    border-color: #c13737;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
}

.uploadBtnWrapper input[type=file] {
    height: 60px;
    width: 60px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;

}
.cardOverlay
{
    border-radius: 4px;
    color: #fff;
    font-size: 1.1em;
    vertical-align: middle;
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardOverlay span{
    background: #929292;
    color: #fff;
    padding: 15px;
    border-radius: 10px;
}