.buttonPulse{
  animation: pulse 2s infinite;
}

.radioFieldset {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
}
.radioGroup {
    flex-direction: row !important;
}

.radioLabel{
    min-width: 30%;
}

.radioButtons {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
}

.radioButtons :not(button) span:nth-child(1) {
    color: #000 !important;
}

.plan {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
}

.planUpdated {
    display: flex;
    padding: 0 !important;
    margin: 0 !important;
    justify-content: space-between;
    align-items: center;
}

.plan :not(button) span:nth-child(1) {
    color: #000 !important;
}

.toothImages {
     display: grid;
    grid-template-columns: repeat(10, 65px);
    grid-template-rows: repeat(2, 65px);
    grid-gap: 0;
    margin-bottom: 3%;
}

.toothImage {
    display: inline;
    padding: 0;
    position: relative;
    margin: 0;
}

.toothImage img:nth-child(1) {
    width: 65px;
    height: 65px;
    padding: 0;
    margin: 0;
}

.toothImage img:nth-child(2) {
    width: 35px;
    height: 35px;
    position: absolute;
    z-index: 10;
    top: 20%;
    left: 30%;
}

.toothImage img:hover{
    cursor: pointer;
}

@media (max-width:768px){
    .radioButtons {
    width: auto !important;
    display:block !important;
}
.radioFieldset {
    display: block !important;

}

}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.8);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0.4);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0.8);
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0.4);
      box-shadow: 0 0 0 10px rgba(0,0,0, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
      box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}

.list {
    list-style-type: decimal;
    font-weight: bold;
}

.list li {
    padding: 10px;
    border: 1px solid #EDEDED;
    border-top: none;
    padding: 5px;
}

.list li:first-child {
    border-top: 1px solid #EDEDED;
}

.normalText {
    font-weight: normal;
}