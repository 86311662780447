.tabWrap
{
    width: 98%;
    margin: 0 auto;
}
.patientImages {
    list-style-type: none;
    display: inherit;
     padding: 0;
}

.subHeading{
    width: 100%;
    margin: 30px 0px 0px 30px;
    color: red;
}

h3{
    display:block;
    width:100%;
    margin:15px;
}

.headerWrapper header {
    box-shadow: none;
}

.twoColumnImage img {
    width: 100%;
}

@media (max-width:767px){
    .MuiGrid-grid-xs-4{
    max-width: 100% !important;
    flex-basis: 100% !important
}
}


.imageUploadArea{
    border: 1px dashed;
    height: 200px;
    width: 90%;
    display: table;
    position: relative;
    overflow: hidden;
}

.imageUploadArea > .cbctimageName
{
    width: 50%;
    display: block;
    padding-left: 12px;
}

.imageUploadArea span
{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 29px;
    color: #ddd;
}

.cbctimageRemove{

    display: inline !important;
    color: red !important;
    cursor: pointer;
}

.uploadBtnWrapper {
  overflow: hidden;
  display: inline-block;
  cursor:pointer;
  width: 100%;
}

.uploadBtnWrapper .uploadBtn {
    background-color: #9998da;
    padding: 8px;
    border: none;
    font-size: 20px;
    font-weight: bold;
    width: 60px;
    height: 40px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
}

.imageUploadArea .remove {
    padding: 15px;
    border-radius: 5%;
    border: none;
    font-size: 15px;
    width: auto;
    height: auto;
    cursor: pointer;
    background-color: #c13737;
    border-color: #c13737;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
}

.uploadBtnWrapper input[type=file] {
  height: 42px;
    width: 60px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;

}

.uploadFooter{
    position: absolute;
    right: 0;
    margin-top: 12px;
}