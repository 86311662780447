/* .toothImages {
     display: grid;
    grid-template-columns: repeat(10, 65px);
    grid-template-rows: repeat(2, 65px);
    grid-gap: 0;
    margin-bottom: 3%;
}

.toothImage {
    display: inline;
    padding: 0;
    position: relative;
    margin: 0;
}

.toothImage img:nth-child(1) {
    width: 65px;
    height: 65px;
    padding: 0;
    margin: 0;
}

.toothImage img:nth-child(2) {
    width: 35px;
    height: 35px;
    position: absolute;
    z-index: 10;
    top: 20%;
    left: 30%;
}

.toothImage img:hover {
    cursor: pointer;
} */

.UserData ul {
    margin-top: 0;
}

.LeftGrid{
	border-right: 1px solid #ddd;
}

.UserInfo {
    border-bottom: 1px solid #ddd;
    /* width: fit-content; */
    /* min-height: 6%; */
}

.userImage {
    display: inline;
}

.userImage img {
    max-width: 80px;
    max-height: 80px;
    display: inline;
}

.UserData {
    display: inline;
    float: right;
}

.LeftGrid ul {
    border-bottom:1px solid #ddd;
    margin-bottom: 10px;
}
.UploadedImages img {
    width: 100%;
    /* height: 80%; */
}

.gridList {
    width: 500;
    height: 450;
}

.errorLabel {
    display: block;
    width: 100%;
    color: red;
    margin: 0;
    font-weight: 800;
}

.errorList {
    list-style-type: decimal;
    /* border: 0; */
    color: red;
    /* border-bottom: none !important; */
    /* line-height: 2; */
}

/* @media only screen and (max-width: 768px) {
  .toothImages {
            overflow-x: scroll;
    }

}

@media only screen and (max-width: 600px) {
  .toothImages {
        overflow-x: scroll;
    }
} */

.list {
    list-style-type: decimal;
    font-weight: bold;
}

.listItem {
    padding: 10px;
    border-bottom: 1px solid #EDEDED;
    border-left: 1px solid #EDEDED;
}

.normalText {
    font-weight: normal;
}

.summaryImg {
    height: 200px;
    width: 200px;
    object-fit: contain;
}

.summaryImgPos {
    height: 200px;
    width: 200px;
    object-fit: cover;
}

.noBulletList {
    list-style-type: none;
    padding: 0;
}