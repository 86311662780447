.iconButtonArrowContainer {
    display: inline;
    vertical-align: top;
}

.gridContainer{
	/* margin-left: 15%; */
	margin-bottom: 48px;
}

.iconButton {
    color: white !important;
}

@media only screen and (max-width: 768px) {

	.iconButtonContainer p {
    display: none;
	}

	.iconButtonArrowContainer button {
    width: 15%;
	}

	.gridContainer{
		margin-bottom: 5%;
		margin-left: 0;
		flex-wrap: nowrap !important;
    	overflow-x: scroll;
	}
	.gridContainerLast{
		margin-left: 12px !important;
	}

	.iconButton {
	    width: 45px;
	    height: 45px;
	}
}

.CustomBadgeContainer {
    position: relative;
}

span.CustomBadge {
    color: red;
    position: absolute;
   	right: 0;
    left: 0;
    margin-left: 45px;
    top: -15px;
    font-size: 40px;
}


/* .customScrollBar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.customScrollBar::-webkit-scrollbar
{
	width: 6px !important;
	background-color: #F5F5F5;
}

.customScrollBar::-webkit-scrollbar-thumb
{
	background-color: #5954a0;
} */