.imageUploadArea{
	border: 1px dashed;
    height: 425px;
    width: 100%;
    display: table;
    position: relative;
    overflow: hidden;
}

.error{
    color: red;
    margin-top: 0;
    font-size: 14px;
}
.imageUploadArea span
{
	display: table-cell;
    vertical-align: middle;
    font-size: 29px;
    color: #ddd;
}

.uploadBtnWrapper {
  overflow: hidden;
  display: inline-block;
  cursor:pointer;
  width: 100%;
}

.uploadBtnWrapper .uploadBtn {
    background-color: #9998da;
    padding: 8px;
    border: none;
    font-size: 20px;
    font-weight: bold;
    width: 60px;
    height: 40px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
}

.imageUploadArea .remove {
    padding: 15px;
    border-radius: 5%;
    border: none;
    font-size: 15px;
    width: auto;
    height: auto;
    cursor: pointer;
    background-color: #c13737;
    border-color: #c13737;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
}

.uploadBtnWrapper input[type=file] {
  height: 42px;
    width: 60px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;

}

.uploadFooter{
    position: absolute;
    right: 0;
    margin-top: 12px;
}

.customButton button {
    background-color: #593687;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 10px;
    cursor: pointer;
}
.customButton
{
    margin-top: 30px;
}