.linkWrapperMobile{
	display: none;
	float: right;
}

.navlinkBtnActive
{
    border: solid 0.8px white !important;
    color: rgba(89,54,135,1) !important;
    background: white !important;
    padding: 4px 24px !important;
    font-weight: 300;
}

.navlinkBtn
{
    border: solid 0.8px white !important;
    color: white !important;
    padding: 4px 24px !important;
    font-weight: 300;
}

.navlinkBtn:hover{
	background: white;
   color: rgba(89,54,135,1);
}

@media only screen and (max-width: 600px) {
 .linkWrapper {
    display: none;
	}
	.linkWrapperMobile{
    display: block;
    margin-top: 8px;
	}
  .navlinkBtn
  {
    color: black !important;
  }
}