.newAddress {
    width: 100% !important;
    background: #f9f9f9;
    border-top: 1px solid #e6e6e6;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 16px;
}

.required:after{
    content: '*';
    color: "red";
    marginLeft: "3px";
}